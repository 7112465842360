import { useState, createRef } from "react";
import './ContactPage.scss'
import { Form, Row, Col } from 'react-bootstrap'
import styles from "../../config/styles";
//import { FileUploader } from "../../components/UploadButton";
import ReCAPTCHA from "react-google-recaptcha";
import { Footer } from "../../components/Footer"
import Regex from "../../config/regex";
import { PopUp } from "../../components/PopUp";
import { useNavigate } from 'react-router-dom';
import { ExternalLinks } from "../../config/urls";
import { mailBreakLine } from "../../config/helpers";


const defaultObject = "Ouverture de compte"
const recaptchaRef = createRef();

export function ContactPage(){
    const [validated, setValidated] = useState(false);
    const [captchaErr, setCaptchaErr] = useState(false);
    const [ form, setForm ] = useState({})
    const [ errors, setErrors ] = useState({})
    const [ object, setObject ] = useState(defaultObject)
    const [ showPopup, setShowPopup] = useState(false);
    //const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const setField = (field, value) => {
        setForm({
          ...form,
          [field]: value
        })
        if ( !!errors[field] ) setErrors({
          ...errors,
          [field]: null
        })
    }

    const findFormErrors = () => {
        const { raisonSocial, lastName, firstName, phone, email, activite, volume, besoins } = form
        const newErrors = {}

        if ( !raisonSocial || raisonSocial.length <2 ) newErrors.raisonSocial = 'Text trop court'
        else if ( raisonSocial.length > 40 ) newErrors.raisonSocial = 'Text trop long!'

        if ( !firstName || firstName.length <2 ) newErrors.firstName = 'Prénom trop court'
        else if ( firstName.length > 20 ) newErrors.firstName = 'Prénom trop long!'
        
        if ( !lastName || lastName.length <2 ) newErrors.lastName = 'Nom trop court'
        else if ( lastName.length > 20 ) newErrors.lastName = 'Nom trop long!'

        if ( !phone || !Regex.phone.test(phone) ) newErrors.phone = 'Téléphone incorrect'

        if ( !email || !Regex.email.test(email) ) newErrors.email = 'Email incorrect'

        if ( !activite || activite.length <2 ) newErrors.activite = 'Text trop court'
        else if ( activite.length > 70 ) newErrors.activite = 'Text trop long!'

        if ( !volume || volume.length <2 ) newErrors.volume = 'Text trop court'
        else if ( volume.length > 20 ) newErrors.volume = 'Text trop long!'

        if ( !besoins || besoins.length <2 ) newErrors.besoins = 'Text trop court'
        else if ( besoins.length > 1000 ) newErrors.besoins = 'Text trop long!'
    
        return newErrors
    }

      
    /*const handleFile = (file) => {
        console.log(file.webkitRelativePath)
        setFile(file)
    }*/

    const handlePopupClose = () =>{
        setShowPopup(false)
        navigate('/');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors()
        const htmlForm = event.currentTarget;
        if (htmlForm.checkValidity() === false && Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            event.stopPropagation();
        }
        else{
            const recaptchaValue = recaptchaRef.current.getValue();
            if (recaptchaValue){
                window.open("mailto:"+ExternalLinks.contactEmail+'?cc=&subject=Technicien.club Contact&body='+
                    "Raison social: "+form.raisonSocial+mailBreakLine+
                    "Nom: "+form.lastName+mailBreakLine+
                    "Prénom: "+form.firstName+mailBreakLine+
                    "Télephone: "+form.phone+mailBreakLine+
                    "Email: "+form.email+mailBreakLine+
                    "Objet: "+form.object+mailBreakLine+
                    "Volume de réparation mensuel: "+form.volume+mailBreakLine+
                    "Besoins: "+form.besoins+mailBreakLine
                , '_blank').focus()
                setShowPopup(true)
            }
            else{
                setCaptchaErr(true)
            }
        }
        setValidated(true);
    }

    return(
        <>
         <div className='contact-container'>
             <div className='contact-title'>
                 <h1>Hello 👋</h1>
                 <h2>Veuillez remplir le formulaire ci-dessous pour qu’on puisse vous rappeler</h2>
             </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <Row>
                    <Form.Group className="mb-3" controlId="raisonSocial">
                        <p>Votre raison sociale *</p>
                        <Form.Control required type="text" style={styles.Input}
                            onChange={ e => setField('raisonSocial', e.target.value) }
                            name='raisonSocial'
                            pattern="^(.{2,40})$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.raisonSocial}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className='name-col'>
                    <Form.Group as={Col}  controlId="lastName">
                        <p>Votre nom *</p>
                        <Form.Control required type="text" style={styles.Input} 
                        onChange={ e => setField('lastName', e.target.value) }
                        pattern="^(.{2,20})$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="firstName">
                        <p>Votre Prénom *</p>
                        <Form.Control required type="text" style={styles.Input}
                        onChange={ e => setField('firstName', e.target.value) }
                        pattern="^(.{2,20})$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="phone">
                        <p>Téléphone *</p>
                        <Form.Control required type="text" style={styles.Input}
                            onChange={ e => setField('phone', e.target.value) }
                            pattern="^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="email">
                        <p>Email *</p>
                        <Form.Control required type="email" style={styles.Input}
                            onChange={ e => setField('email', e.target.value) }
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3">
                        <p>Objet *</p>
                        <Form.Select required id="select" style={styles.Select} 
                            value={object} onChange={e => setObject(e.target.value)}
                        >
                            <option value={defaultObject}>{defaultObject}</option>
                            <option value="Demande de partenariat">Demande de partenariat</option>
                            <option value="Demande d'informations">Demande d'informations</option>
                            <option value="Autres demandes">Autres demandes</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="activite">
                        <p>Votre activité actuelle *</p>
                        <Form.Control required type="text" style={styles.Input}
                            onChange={ e => setField('activite', e.target.value) }
                            pattern="^(.{2,70})$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.activite}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="volume">
                        <p>Votre volume de réparation mensuel *</p>
                        <Form.Control required type="text" style={styles.Input}
                            onChange={ e => setField('volume', e.target.value) }
                            pattern="^(.{2,20})$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.volume}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                
                <Row>
                    <Form.Group className="mb-3" controlId="besoins">
                        <p>Décrivez-nous vos besoins *</p>
                        <Form.Control required as="textarea" rows={7} style={styles.Input}
                            onChange={ e => setField('besoins', e.target.value) }
                            pattern="^(.{2,1000})$"
                        />
                        <Form.Control.Feedback type="invalid">{errors.besoins}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                {/*<Row>
                    <Form.Group className="mb-3" controlId="file">
                        <p>Pièce jointe</p>
                        <FileUploader handleFile={handleFile}/>
                    </Form.Group>
                </Row>*/}
                <ReCAPTCHA
                    className='captcha'
                    size="normal"
                    sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                    ref={recaptchaRef}
                    hl="fr"
                    onChange={() => setCaptchaErr(false)}
                />
                <h6 className='captcha-error'>{captchaErr? 'Vérifier le captcha':null}</h6>
                <button className='submit-btn' type="submit" ><h4>Envoyer</h4></button>
            </Form>
         </div>
         <Footer />
         <PopUp 
            title={'Merci '+form.firstName+','}
            body={popupBody}  
            span={popupSpan} 
            show={showPopup}  
            handleClose={handlePopupClose}
        />
        </>
    )
}

const popupBody = [
    'Votre message a été transmis à notre équipe.',
    'Nous vous contacterons dans les meilleurs délais.'
]
const popupSpan = 'A bientôt 👋'