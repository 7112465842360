import "./HomePage.scss";
//import FingerLeft from '../../assets/emoji/finger_left.svg'
import { useEffect, useState } from "react";
import Phones from '../../assets/images/bg_gradient.png'
import PhonesMobile from '../../assets/images/bg_gradient_mobile.png'
import { Footer } from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import { URLS } from "../../config/urls"

const DEVICES = ['Smartphones', 'Tablettes', 'Ordinateurs', 'Consoles', 'Clients', 'Collaborateurs']
const DEVICES_DELAY = 2 * 1000 

export function HomePage() {
    const [deviceIndex, setDeviceIndex] = useState(0)

    const navigate = useNavigate();

    const goToContact = () => navigate('/'+URLS.CONTACT)
    const goToInfo = () => navigate('/'+URLS.INFO)

    useEffect(()=>{
        let timer = setTimeout(() => {
            let _deviceIndex = deviceIndex+1 < DEVICES.length? deviceIndex+1 : 0
            setDeviceIndex(_deviceIndex) 
        },
        DEVICES_DELAY)
        return () => {
          clearTimeout(timer);
        };
    },[deviceIndex])

    return(
        <div className='home-container'>
            <div className='pretitle-container'>
                <p>Réparation - Reconditionnement - Recyclage</p>
            </div>
            <div className='title-container'>
                <p>Le Service Après Vente<br/> qui prend soin de vos <br/><span>{DEVICES[deviceIndex]}</span></p>
            </div>
            <div className='btn-block'>
                <p>Vous n’avez pas encore de compte ? </p>
                <div className='main-btn' onClick={goToContact}>
                    <p>C’est par ici <span>👈</span></p>
                </div>
                <div style={{display:'flex', flexDirection: 'row'}}>
                    <span>Envie</span>  
                    <u onClick={goToInfo}>d’en savoir plus ?</u>
                </div>
            </div>
            <div className='phones-img'>
                <img src={Phones} alt="phones"/>
            </div>
            <div className='phones-img-mobile'>
                <img src={PhonesMobile} alt="phones"/>
            </div>
            <Footer />
        </div>
    )
}
