const Input = {
    backgroundColor: "white",
    boxSizing: "border-box", 
    borderRadius: "9px", 
    padding: '10px 15px',
}
const Select = {
    backgroundColor: "white",
    boxSizing: "border-box", 
    borderRadius: "9px", 
    padding: '10px 15px'
}

const styles ={
    Input,
    Select
}
export default styles