import './PopUp.scss'
import { Button, Modal } from 'react-bootstrap';


export function PopUp(props){

    return(
        <>
            <Modal show={props.show} onHide={props.handleClose}  size="md" centered>
                <Modal.Header  style={{border: 'none'}}/>
                <Modal.Body>
                    <div className='popup-body-container'>
                        <h1>{props.title}</h1>
                        <p>
                            {
                                props.body.map((text, index) =><p key={index}>{text}<br/></p>)
                            }
                            <span>{props.span}</span>
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{border: 'none', alignSelf: 'center'}}>
                    <Button variant="link" onClick={props.handleClose} style={{alignSelf: 'center'}}>
                        <p className='modal-close-btn'>Fermer</p>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}