 export const URLS = {   
    Home: 'home',
    INFO: 'info',
    SERVICES:  'services',
    TOOLS: 'tools',
    CONTACT: 'contact',
    ACCOUNT: 'account',
    POLICY: 'policy',
    TERMS: 'terms'
}
export const ExternalLinks = {
    diagtool: 'https://www.diagtool.io/',
    contactEmail: 'said@technicien.club',
    login: 'https://www.technicien.club/myspace/login'
}