import './Account.scss'
import { useState } from "react";
import { Form, Row } from 'react-bootstrap'
import styles from "../../config/styles";
import { Footer } from "../../components/Footer"
import { PopUp } from "../../components/PopUp";

export function Account() {
    const [validated, setValidated] = useState(false);
    const [id, setId] = useState('')
    const [password, setPassword] = useState('')
    const [ showPopup, setShowPopup] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const htmlForm = event.currentTarget;
        if (htmlForm.checkValidity() === false ) {
            event.stopPropagation();
        }
        else{
            alert('Your account details:\n\nId: '+id+'\nPassword: '+password)
        }
        setValidated(true);
    }

    const handlePopupClose = () =>{
        setShowPopup(false)
    }
    return(
        <div className='account-all-container'>
            <div className='account-container'>
                <h1>Accédez à votre compte</h1>
                <h2>Suivez et gérez vos réparations en temps réel.</h2>
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                    <Row style={{marginBottom: 20}}>
                        <Form.Group controlId="id">
                            <Form.Control required type="text" style={styles.Input}
                                onChange={ e => setId(e.target.value) }
                                name='id'
                                placeholder='Identifiant'
                                //pattern="^(.{2,20})$"
                            />
                            <Form.Control.Feedback type="invalid">Enter votre identifiant</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="password">
                            <Form.Control required type="password" style={styles.Input}
                                onChange={ e => setPassword(e.target.value) }
                                name='password'
                                placeholder='Mot de passe'
                                //pattern="^(.{2,20})$"
                            />
                            <Form.Control.Feedback type="invalid">Mot de passe incorrect</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <button className='connect-btn' type="submit" ><h4>Se connecter</h4></button>
                </Form>
                <h3 onClick={()=>setShowPopup(true)}>Mot de passe oublié ?</h3>
            </div>
            <div className='mobile-footer-hide'>
                <Footer />
            </div>
            <PopUp 
                title='Oups !'
                body={popupBody}  
                span={popupSpan} 
                show={showPopup}  
                handleClose={handlePopupClose}
            />
        </div>
    )
}
const popupBody = [ 'Pour des raisons de sécurité, cette opération ne peut être réalisée.']
const popupSpan = 'Merci de contacter votre chargé de compte.'