import './App.scss';
import { useState } from 'react'
import { Navbar, Nav} from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom'
import { URLS, ExternalLinks } from "./config/urls";
import LOGO1 from "./assets/icons/logo1.png"
import LOGO2 from "./assets/icons/logo2.png"
import INFO_ICON from "./assets/icons/fontisto_info.svg"
import SERVICES_ICON from "./assets/icons/grommet-icons_services.svg"
import TOOLS_ICON from "./assets/icons/clarity_tools-line.svg"
import CONTACT_ICON from "./assets/icons/bx_bx-phone-call.svg"
import ACCOUNT_ICON from "./assets/icons/codicon_account.svg"
import ScrollToTop from "./config/scrollToTop";
import { HomePage } from "./routes/Home";
import { InfoPage } from "./routes/Info";
import { ServicesPage } from "./routes/Services";
import { ToolsPage } from "./routes/Tools/ToolsPage";
import { ContactPage } from "./routes/Contact";
import { Account } from "./routes/Account";
import { Terms } from "./routes/Terms";
import { Policy } from "./routes/Policy";

const Header = () => {
  const  location = useLocation();
  const [expanded, setExpanded] = useState(false)
  const setToggle = (state) => {
    setExpanded(state)
  }  
  const NavBarItem = (props) =>{
    return(
      <div className={expanded? 'item-with-icon': ''}>
        {expanded? <div style={{backgroundImage: `url(${props.icon})`}}/>: null}
        <h4>{props.text}</h4>
      </div>
    )
  }
  return(
    location.pathname==='/'+URLS.ACCOUNT ? <></>:
      <>
        <Navbar collapseOnSelect expand="md" bg="white" variant="light" className='navbar' fixed='top'
        onToggle={setToggle} >
            <Navbar.Brand href="/">
              <img src={LOGO1} className='navbar-logo' alt="logo1"/>
              <img src={LOGO2} className='navbar-logo' alt="logo2"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{borderWidth: 0}}/>
            <Navbar.Collapse id="responsive-navbar-nav"  className='navbar-collapse'>
              <Nav className="navbar-items" activeKey={location.pathname}>
                <Nav.Link href={"/"+URLS.INFO}><NavBarItem text='Qui sommes-nous ?' icon={INFO_ICON} /></Nav.Link>
                <Nav.Link href={"/"+URLS.SERVICES}><NavBarItem text='Services' icon={SERVICES_ICON} /></Nav.Link>
                <Nav.Link href={"/"+URLS.TOOLS}><NavBarItem text='Outils' icon={TOOLS_ICON} /></Nav.Link>
                <Nav.Link href={"/"+URLS.CONTACT}><NavBarItem text='Contact' icon={CONTACT_ICON} /></Nav.Link>
                <Nav.Link href={ExternalLinks.login}><NavBarItem text='Mon Compte' icon={ACCOUNT_ICON} /></Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
        <div style={{height: 51}}/>
      </>
  )
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>                
        <Route  path='/' element={<HomePage/>} />
          <Route path={URLS.INFO} element={<InfoPage/>}/>  
          <Route path={URLS.SERVICES} element={<ServicesPage/>}/>  
          <Route path={URLS.TOOLS} element={<ToolsPage/>}/>  
          <Route path={URLS.CONTACT} element={<ContactPage/>}/> 
          <Route path={URLS.ACCOUNT} element={<Account/>}/>     
          <Route path={URLS.TERMS} element={<Terms/>}/>  
          <Route path={URLS.POLICY} element={<Policy/>}/>  
      </Routes>
    </Router>
  );
}

export default App;
