import './BrandsLogo.scss'
import { useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import BRANDS from "./Brands";

export function BrandsLogo(){
    const [show, setShow] = useState(false);
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <div className='logo-brand'>
                {
                    BRANDS.map((brand, index) => <div key={index}style={{backgroundImage: `url(${brand})`}} />)
                }
            </div>
            <div className='logo-btn-mobile' onClick={handleShow}><p>Voir les Marques</p></div>
            <Modal show={show} onHide={handleClose}  size="lg">
                <Modal.Header closeButton style={{border: 'none'}}/>
                <Modal.Body>
                    <div className='logo-brand-mobile'>
                        {BRANDS.map((brand, index) => <div key={index} style={{backgroundImage: `url(${brand})`}} />)}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{border: 'none', alignSelf: 'center'}}>
                <Button variant="link" onClick={handleClose} style={{alignSelf: 'center'}}>
                    <p className='modal-close-btn'>Fermer</p>
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}