import "./ServicesPage.scss";
import { MoreButton } from "../../components/PricingFormula";
import Frame1 from '../../assets/icons/Frame1.svg'
import Frame2 from '../../assets/icons/Frame2.svg'
import Frame3 from '../../assets/icons/Frame3.svg'
import Frame4 from '../../assets/icons/Frame4.svg'
import Frame5 from '../../assets/icons/Frame5.svg'
import Frame6 from '../../assets/icons/Frame6.svg'
import { Footer } from "../../components/Footer";
import { BrandsLogo } from "../../components/BrandsLogo";
import { handleScroll } from "../../config/helpers";
import { useNavigate } from 'react-router-dom';
import { URLS } from "../../config/urls"

export function ServicesPage() {
    const navigate = useNavigate();
    const goToContact = () => navigate('/'+URLS.CONTACT)
    
    return(
        <div className='services-container'>
            <div className='phone-img-block'>
                <h1>Nous réparons vos<br /> Carte mères</h1>
                <p>32 411 carte mères réparées en 2021</p>
            </div>
            <div className='go-down-btn-container2'>
                <div className='go-down-btn' onClick={handleScroll}><div/></div>
            </div>
            <BrandsLogo />
            <div className='title-block'>
                <h2>Nos domaines d’interventions</h2>
                <div />
                <p>Nous sommes experts en Micro-Soudure et réparations carte mères, 80% de notre activité est consacrée aux interventions de la dernière chance mais intervenons aussi sur toutes les réparations de niveaux inférieurs.</p>
                <p>Avec un service logistique intégré et des solutions clés en mains à disposition, notre équipe se charge de l'acheminement des appareils depuis et vers n'importe quel endroit.</p>
            </div>
            <div className='domain-block'>
                <div>
                    <div style={{backgroundImage: `url(${Frame1})`}} />
                    <h3>Logistique</h3>
                    <p>Gagnez du temps ! Notre équipe logistique s'occupe de gérer vos envois/réceptions des appareils vers et depuis toute l'Europe.</p>
                </div>
                <div>
                    <div style={{backgroundImage: `url(${Frame2})`}} />
                    <h3>Réparation L1 {'&'} L2</h3>
                    <p>Nous assurons des diagnostics complets ainsi que les interventions des niveaux techniques 1 et 2 : Réparation écrans, batterie, connectiques et caméras ...</p>
                </div>
                <div>
                    <div style={{backgroundImage: `url(${Frame3})`}} />
                    <h3>Réparation carte mère L3 {'&'} L4</h3>
                    <p>Vos réparations carte mère sont entre de bonnes mains, nos techniciens interviennent sur tous types de pannes et remplacements de circuits sur vos appareils.</p>
                </div>
                <div>
                    <div style={{backgroundImage: `url(${Frame4})`}} />
                    <h3>Pièces détachées</h3>
                    <p>Grâce à notre réseau international, nous vous assurons un approvisionnement régulier et de qualité. Nous sélectionnons et garantissons chacun de nos produits.</p>
                </div>
                <div>
                    <div style={{backgroundImage: `url(${Frame5})`}} />
                    <h3>Reconditionnement</h3>
                    <p>Nous mettons nos compétences en reconditionnement d'écrans et appareils à votre service et vous assurons un recyclage maximum de chaque composant.</p>
                </div>
                <div>
                    <div style={{backgroundImage: `url(${Frame6})`}} />
                    <h3>Formations</h3>
                    <p>Notre équipe de techniciens niveau 3 vous assure une formation technique qualitative basée sur des acquis pratiques et une expérience de +20 ans d'exercice.</p>
                </div>
            </div>
            <div className='title-block'>
                <h2>Nos formules</h2>
                <div />
                <p>Parce que chaque professionnel est unique, nos solutions sont faites sur mesure pour chacun de nos partenaires. Selon vos besoins, nous étudions tous les scénarios possibles afin de vous apporter les meilleurs résultats.</p>
                <p>La polyvalence et la digitalisation de nos outils et solutions font de notre équipe une team d'exception prête à relever tous vos challenges.</p>
            </div>
            <div className='formule-block'>
                <div>
                    <div>
                        <h3>Drop repair</h3>
                        <h6>Adaptée à tous type de besoins</h6>
                        <h5>Sans volume minimum</h5>
                            <p>Vos clients expédient les appareils défectueux directement en atelier, nous effectuons un diagnostic et vous soumettons un devis. Nous réexpédions l'appareil directement chez votre client après réparation.</p>
                    </div>
                    <MoreButton redirect={goToContact}/>
                </div>
                <div>
                    <div>
                        <h3>Mass Repair</h3>
                        <h6>Adaptée aux wholesalers</h6>
                        <h5>Sous conditions de volume</h5>
                            <p>Vous faites parvenir vos lots d'appareils à réparer directement dans nos ateliers, nous intervenons dessus selon le diagnostic établi et vous réacheminons le lot après intervention.</p>
                    </div>
                    <MoreButton redirect={goToContact}/>
                </div>
                <div>
                    <div>
                        <h3>Corporate</h3>
                        <h6>Adaptée aux entreprises disposant de flottes</h6>
                        <h5>Nous consulter pour en savoir plus</h5>
                            <p>Nous gérons la réparation de vos flottes d'entreprises ou les appareils de vos collaborateurs avec possibilité de réparation en atelier sur envoi postal, dans vos locaux ou chez vous via notre service à domicile.</p>
                        
                    </div>
                    <MoreButton redirect={goToContact}/>
                </div>
            </div>
            <Footer />
        </div>
    )
}