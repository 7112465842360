import './InfoPage.scss'
import { useState } from 'react'
import Icon1 from '../../assets/icons/Group130.svg'
import Icon2 from '../../assets/icons/Group131.svg'
import Icon3 from '../../assets/icons/Group132.svg'
import Arrow1 from '../../assets/icons/Arrow1.svg'
import Arrow2 from '../../assets/icons/Arrow2.svg'
import { Footer } from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import { URLS } from "../../config/urls"
import { handleScroll } from "../../config/helpers";

export function InfoPage()  {
    const [navbarIndex, setNavbarIndex] = useState(0)

    const navigate = useNavigate();
    const goToContact = () => navigate('/'+URLS.CONTACT)

    const _changeText = (parametre, text) =>{
        return(
            parametre === 1 ?
            <p>La qualité n'est pas une option. Quelque soit le niveau d'intervention, toutes nos réparations sont garanties<sup>(1)</sup> jusqu'à 90 jours.</p>:
            parametre === 2 ?
            <p>Nos process sont étudiés pour que le temps d'immobilisation de vos appareils soit réduit au minimum. Notre temps moyen d'intervention est de 48h<sup>(2)</sup></p>:
            parametre === 3 ?
            <p>Gagnez en sérénité et confiez-nous la gestion des appareils de vos collaborateurs. Nous réparons vos appareils en atelier; dans vos locaux ou chez vous !<sup>(3)</sup>.</p>:
            <p>{text}</p>
        )
    }

    return(
        <>
            <div className='info-container'>
                <div className='main-img-block'>
                    <h1>Technicien Club</h1>
                    <p>+20 ans d’expérience à votre service</p>
                </div>
                <div className='go-down-btn-container'>
                    <div className='go-down-btn' onClick={handleScroll}>
                    <div/></div>
                </div>
                <div className='info-block'>
                    <div>
                        <span>L’Entreprise</span>
                        <h2>Qui sommes-nous ?</h2>
                        <div className='main-btn' onClick={goToContact}>
                            <p>Prendre Contact</p>
                            <div className='arrow-right'/>
                        </div>
                    </div>
                    <div className='right-text-block'>
                        <p>Depuis près de 20 ans dans le domaine du Service Après Vente et des réparations inter-entreprises, nous mettons à disposition de nos partenaires depuis 2018, une solution unique en France à destination de tous les acteurs du marché.</p>
                        <p>Proposer des services de réparation en marque blanche est la suite logique à notre parcours dans le domaine technique et commercial.</p>
                        <p>Désormais, accéder et proposer des services de réparation est chose possible !</p>
                        <p>Le tout en un click</p>
                    </div>
                </div>
                <div className='middle-img' />
                <div className='right-text-block-only'>
                    <div/>
                    <div className='right-text-block'>
                        <p>Si comme nous vous êtes animés par la volonté de relever des nouveaux challenges et avoir de l'impact sur le devenir de notre planète, venez en discuter avec nous !</p>
                        <p>Notre équipe saura vous proposer des solutions sur mesure et vous apportera une expertise à 360° pour une gestion efficace de vos flux.</p>
                    </div>
                </div>
                <h2 className='middle-title'>Votre Partenaire Réparation</h2>
                <div className='middle-nav-block'>
                    <div className='middle-navbar'>
                        <div className={navbarIndex === 0? 'active-nav-item':''} onClick={()=>setNavbarIndex(0)}>
                            <h3>Nos engagements</h3>
                        </div>
                        <div className={navbarIndex === 1? 'active-nav-item':''} onClick={()=>setNavbarIndex(1)}>
                            <h3>Notre démarche</h3>
                        </div>
                        <div className={navbarIndex === 2? 'active-nav-item':''} onClick={()=>setNavbarIndex(2)}>
                            <h3>Nos clients</h3>
                        </div>
                    </div>
                    <div className='middle-nav-container'>
                        {
                            staticNavbarData[navbarIndex].map((obj, index) => {
                                return(
                                    <div key={index}>
                                        <h4>{obj.title}</h4>
                                        {
                                            obj.title === "Garantie"? _changeText(1,obj.text):
                                            obj.title === "Rapidité"? _changeText(2,obj.text):
                                            obj.title === "Grandes entreprises"? _changeText(3,obj.text):
                                            <p>{obj.text}</p>
                                        }
                                        {obj.text2? <p>{obj.text2}</p>: null}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <h2 className='middle-title'>Comment ça marche ?</h2>
                <div className='final-block'>
                    <div>
                        <div className='icon'>
                            <div style={{backgroundImage: `url(${Icon1})`}} />
                        </div>
                        <h5>1. Prise de contact</h5>
                        <p>Faites-nous part de vos besoins via le formulaire de contact <u onClick={goToContact}>ici</u>.</p>
                    </div>

                    <div>
                        <div className='icon'>
                            <div style={{backgroundImage: `url(${Icon2})`}} />
                        </div>
                        <h5>2. Analyse de vos besoins</h5>
                        <p>Nous étudions votre demande et établissons une proposition.</p>
                    </div>
                    <div>
                        <div className='icon'>
                            <div style={{backgroundImage: `url(${Icon3})`}} />
                        </div>
                        <h5>3. Ouverture de compte</h5>
                        <p>Votre compte est ouvert, vous pouvez nous envoyer vos appareils.</p>
                    </div>
                </div>
                <div className='final-block-arrows'>
                    <div style={{backgroundImage: `url(${Arrow1})`}} />
                    <div style={{backgroundImage: `url(${Arrow2})`}} />
                </div>
                <div className='small-footer'>
                    <p>(1) Pour plus d'informations, se référer aux conditions générales de vente et de garantie.</p>
                    <p>(2) Ce délai peut varier selon la période et les volumes en cours de traitement ou annoncés.</p>
                    <p>(3) Service soumis à conditions, nous consulter pour les interventions à domicile ou au bureau.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export const staticNavbarData = [
    [
        {title: "Transparence", text: "Tout est centralisé sur votre compte en ligne, vous suivez vos réparations en temps réel et êtes notifiés à chaque étape de l'intervention."},
        {title: "Compétences", text: "Tous nos techniciens sont formés aux interventions du niveau 3 et assurent les réparations les plus complexes sur tous les types d'appareils."},
        {title: "Rapidité", text: "Nos process sont étudiés pour que le temps d'immobilisation de vos appareils soit réduit au minimum. Notre temps moyen d'intervention est de 48h(2)"},
        {title: "Flexibilité", text: "Optez pour une solution sur mesure, nous adaptons nos process pour vous apporter une solution sur mesure quelques soient vos besoins."},
        {title: "Garantie", text: "La qualité n'est pas une option. Quelque soit le niveau d'intervention, toutes nos réparations sont garanties(1) jusqu'à 90 jours."},
        {title: "Confidentialité", text: "Les données collectées dans le cadre de nos prestations sont traitées conformément à notre stratégie RGPD suivie par un cabinet d'avocats spécialisés."}
    ],
    [
        {title: "Confidentialité", text: "Tous nos techniciens sont signataires de notre charte interne de confidentialité et veillent à ce que vos appareils ainsi que leur contenus soient protégés."},
        {title: "Conformité RGPD", text: "Les données collectées dans le cadre de nos prestations sont traitées conformément à notre stratégie RGPD suivie par un cabinet d'avocats spécialisés."},
        {title: "Formations et réemploi", text: "Nous organisons des sessions de trainig régulières et formons des profils issus des écoles de la dernière chance ou en reconversion professionnelle."},
        {title: "Protection de l’environnement", text: "Notre démarche de recyclage à laquelle nos équipes sont tant attachés se traduit par la réutilisation de la plupart des composants viables."}
    ],
    [
        {title: "Magasins spécialisés", text: "Occupez-vous de l'essentiel !", text2: "Magasin de réparation ? Confiez-nous vos réparations micro soudure et problèmes cartes mères."},
        {title: "e-commercants", text: "Réduisez vos remboursements.", text2: "Vendeurs sur les Markets ? On s'occupe de votre sav depuis et vers le client avec notre logistique intégrée."},
        {title: "Reconditionneurs", text: "Augmentez vos revenus !", text2: "Nous assurons tous niveaux d'interventions sur vos lots d'appareils cassés et réduisons vos hs."},
        {title: "Assureurs", text: "Avec des diagnostics précis et des interventions rapides et de qualité, nous vous apportons des solutions sur mesure pour une satisfaction client garantie."},
        {title: "Entreprises", text: "Profitez d’une opportunité unique et augmentez votre volume d’affaires et proposant des services de réparation innovants à vos clients."},
        {title: "Grandes entreprises", text: "Gagnez en sérénité et confiez-nous la gestion des appareils de vos collaborateurs. Nous réparons vos appareils en atelier; dans vos locaux ou chez vous ! (3)."}
    ]
]