import './PricingFormula.scss'

export function MobilePricing (props){
    return(
        <div className='mobile-price'>
            <h1>{props.price}</h1>
            <div>
                <h2>€</h2>
                <span>/ mois</span>
            </div>
        </div>
    )
}

export function MoreButton (props) {
    return(
        <div className='more-btn' onClick={props.redirect}>
            <div><h2>En savoir plus</h2></div>
        </div>
    )
}