import "./Footer.scss";
import { URLS, ExternalLinks } from "../../config/urls";
import { useNavigate } from 'react-router-dom';

export function Footer() {
    const navigate = useNavigate();

    const goToTerms = () => navigate('/'+URLS.TERMS)
    const goToPolicy = () => navigate('/'+URLS.POLICY)
    const goToAccount = () => navigate('/'+URLS.ACCOUNT)
    const goToExternal = () => window.location = ExternalLinks.diagtool
    const goToContact = () => navigate('/'+URLS.CONTACT)

    return(
        <div className='footer'>
            <div className='footer-container'>
                <div className='footer-line'/>
                <span>©2021 TechnicienClub</span>
                <u onClick={goToTerms}>Conditions d’utilisation</u>
                <u onClick={goToPolicy}>Politique de confidentialité</u>
                <u onClick={goToAccount}>Ouvrir un compte</u>
                <u onClick={goToExternal}>Diagtool.io</u>
                <u onClick={goToContact}>Contact</u>
                <div className='footer-line'/>
            </div>
        </div>   
    )
}
