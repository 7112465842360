import './ToolsPage.scss'
import { Footer } from "../../components/Footer";
import DashboardImage from "../../assets/images/dashboard.svg"
import DiagtoolImage from "../../assets/images/diagtool.svg"
import Tool1Icon from "../../assets/icons/Tool1.svg"
import Tool2Icon from "../../assets/icons/Tool2.svg"
import Tool3Icon from "../../assets/icons/Tool3.svg"

export function ToolsPage(){
    return(
        <>
            <div className='tool-container'>
                <div className='tools-title-block'>
                    <h1>Une interface unique</h1>
                    <p>Afin de répondre aux spécificités de notre métier et apporter un maximum de transparence à nos clients, nous avons développé une plateforme dédiée à la gestion quotidienne de nos ateliers.<br/>
                    Votre compte personnel, dès son activation vous permettra de réaliser l'intégralité des opérations de création, suivi, échanges, rapports directement via l'interface dédiée.</p>
                </div>
                <div className='tools-img-mobile'>
                    <div style={{backgroundImage: `url(${DashboardImage})`}} />
                </div>
                <div className='tools-main-block-1'>
                    <div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool1Icon})`}} />
                            </div>
                            <div>
                                <h2>Tableau de bord</h2>
                                <p>Spécialement conçu pour donner un aperçu instantanné et immédiate à un ensemble informations indispensables au pilotage de votre activité tel que : Le taux de réussite, le volume d'appareils réceptionné, en cours ou à venir.... </p>
                            </div>
                        </div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool2Icon})`}} />
                            </div>
                            <div>
                                <h2>Exports et analyses</h2>
                                <p>Exportez en 1 clic au format Excel ou CSV les données issues des BDC crées sur votre compte personnel incluant toutes les informations de vos appareils y compris les informations logistiques et financières.</p>
                            </div>
                        </div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool3Icon})`}} />
                            </div>
                            <div>
                                <h2>Suivi opérationnel</h2>
                                <p>Piloter votre activité en 1 click, c'est maintenant possible grâce à votre compte personnel. Analyser vos flux, informer vos clients ou encore évaluer les interventions en quelques minutes grâce aux mises à jours instantannées.</p>
                            </div>
                        </div>
                    </div>
                    <div className='tools-img'>
                        <div style={{backgroundImage: `url(${DashboardImage})`}} />
                    </div>
                </div>
                <div className='tools-title-block'>
                    <h1>Diagtool</h1>
                    <p>Votre client vous remonte un dysfonctionnement sur un appareil ?<br/>
                        +10% des sav déclarés par les clients n'en sont pas !  <br/>
                        DiagTool apporte une solution fiable à vos problèmes de ""faux sav"" et vous permet de statuer immédiatement sur le retour ou ""non"" d'un appareil en sav.<br/>
                        Notre application mobile vous permet de détecter tous les dysfonctionnements d'un smartphone avant la réparation par un simple test en moins de 10mn.<br/>
                        Il vous suffit de lui faire parvenir un code d'activation unique avec lequel il pourra réaliser son auto diagnostic, recevez le rapport immédiatement sur votre back office et évaluez le retour avant de faire venir l'appareil pour réparation.		
                    </p>
                </div>
                <div className='tools-img-mobile'>
                    <div style={{backgroundImage: `url(${DiagtoolImage})`}} />
                </div>
                <div className='tools-main-block-2'>
                    <div className='tools-img'>
                        <div style={{backgroundImage: `url(${DiagtoolImage})`}} />
                    </div>
                    <div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool1Icon})`}} />
                            </div>
                            <div>
                                <h2>Gagnez du temps</h2>
                                <p>Soyez réactifs et ne perdez plus de temps à échanger avec les utilisateurs. Apportez une réponse immédiate à vos clients qui remontent des dysfonctionnements</p>
                            </div>
                        </div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool2Icon})`}} />
                            </div>
                            <div>
                                <h2>Fiabilisez vos process de prise en charge</h2>
                                <p>Grâce au pré-diagnostic unique réalisé à distance, n'acceptez que les sav avérés et réduisez votre taux de prise en charge sav.</p>
                            </div>
                        </div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool3Icon})`}} />
                            </div>
                            <div>
                                <h2>Evaluation des pannes à distance</h2>
                                <p>Le pré-diagnostic à distance vous permet d'évaluer les couts de réparation et vous apporte une réponse instantannée la prise de décision d'admission en sav.</p>
                            </div>
                        </div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool3Icon})`}} />
                            </div>
                            <div>
                                <h2>Gardez un historique</h2>
                                <p>Les diagnostics réalisés sur un même appareil sont tous archivés sur votre compte. Cela vous permet d'avoir un historique détaillé des pannes survenues.</p>
                            </div>
                        </div>
                        <div className='tools-item'>
                            <div>
                                <div style={{backgroundImage: `url(${Tool3Icon})`}} />
                            </div>
                            <div>
                                <h2>Faites réparer en toute transparence</h2>
                                <p>Une fois tous les éléments de diagnostic en main, vous </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}