import Logo1 from "../../assets/brands/Logo1.svg";
import Logo2 from "../../assets/brands/Logo2.svg";
import Logo3 from "../../assets/brands/Logo3.svg";
import Logo4 from "../../assets/brands/Logo4.svg";
import Logo5 from "../../assets/brands/Logo5.svg";
import Logo6 from "../../assets/brands/Logo6.svg";
import Logo7 from "../../assets/brands/Logo7.svg";
import Logo8 from "../../assets/brands/Logo8.svg";
import Logo9 from "../../assets/brands/Logo9.svg";
import Logo10 from "../../assets/brands/Logo10.svg";
import Logo11 from "../../assets/brands/Logo11.svg";
import Logo12 from "../../assets/brands/Logo12.svg";
import Logo13 from "../../assets/brands/Logo13.svg";
import Logo14 from "../../assets/brands/Logo14.svg";
import Logo15 from "../../assets/brands/Logo15.svg";
import Logo16 from "../../assets/brands/Logo16.svg";
import Logo17 from "../../assets/brands/Logo17.svg";
import Logo18 from "../../assets/brands/Logo18.svg";
import Logo19 from "../../assets/brands/Logo19.svg";
import Logo20 from "../../assets/brands/Logo20.svg";
import Logo21 from "../../assets/brands/Logo21.svg";

const BRANDS = [ Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo9, Logo10, Logo11, Logo12, Logo13, Logo14, Logo15, Logo16, Logo17, Logo18, Logo19, Logo20, Logo21]

export default BRANDS